import { useAuth0 } from '@auth0/auth0-react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUsers } from '../../hooks/api/useUsers';
import Loader from '../Loader';

const NavbarAccountSelector = () => {
  const { fetchAccount } = useUsers();
  const navigate = useNavigate();
  const { loggedInAccount, account, setAccount } = useAuthContext();
  const { isAuthenticated } = useAuth0();
  const [accountList, setAccountList] = useState<Record<string, string>>({});

  if (Object.keys(accountList).length === 0) {
    fetchAccount().then((res) => setAccountList(res || {}));
  }

  const updateAccount = (newAccount: any) => {
    setAccount(newAccount || loggedInAccount);
    if (window.location.pathname.includes('/agreements/')) {
      navigate('/agreements');
    }
  };
  const sortedAccountNames = useMemo(
    () => Object.keys(accountList).sort(),
    [accountList]
  );
  const options = useMemo(
    () =>
      sortedAccountNames.map((name) => ({
        label: `${name} - ${accountList[name]}`,
        id: accountList[name],
      })),
    [accountList, sortedAccountNames]
  );
  const defaultAccount = useMemo(
    () => options.find((option) => option.id === account),
    [account, options]
  );

  if (!isAuthenticated) {
    return <></>;
  }

  while (Object.keys(accountList).length === 0) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItem: 'center',
        width: '100%',
        minWidth: '280px',
      }}
    >
      <Autocomplete
        value={defaultAccount}
        disablePortal
        clearOnEscape
        options={options}
        onChange={(_e, newValue) => {
          updateAccount(newValue?.id);
        }}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};

export default NavbarAccountSelector;
