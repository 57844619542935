import { useAuth0 } from '@auth0/auth0-react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUsers } from '../hooks/api/useUsers';

interface User {
  [key: string]: any;
}

interface AuthContextType {
  user: User | undefined;
  account: string;
  setAccount: (account: string | null) => void;
  fetchAccountList: () => Promise<void>;
  accountList: Record<string, string>;
  loggedInAccount: string;
  isImpersonating: boolean;
  impersonationLock: boolean;
  setImpersonationLock: (lock: boolean) => void;
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  account: '',
  setAccount: () => {},
  fetchAccountList: async () => {},
  accountList: {},
  loggedInAccount: '',
  isImpersonating: false,
  impersonationLock: true,
  setImpersonationLock: () => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthContextProvider = ({ children }: any) => {
  const { user } = useAuth0();
  const { fetchAccount } = useUsers();
  const [impersonationLock, setImpersonationLock] = useState(true);

  const currentAccount =
    user?.[`https://api.jstscifree.com/app_metadata`]?.account;
  const [account, setAccount] = useState<string | null>(null);
  const [accountList, setAccountList] = useState<Record<string, string>>({});
  const [loggedInAccount, setLoggedInAccount] = useState('');

  const savedAccount = sessionStorage.getItem('account');

  useEffect(() => {
    setAccount(savedAccount || currentAccount);
    setLoggedInAccount(currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  useEffect(() => {
    const savedImpersonationLock =
      sessionStorage.getItem('impersonationLock') === 'true';
    setImpersonationLock(savedImpersonationLock);
  }, []);

  const impersonationLockChangeHandler = useCallback(
    (impersonationLockEnabled: boolean) => {
      setImpersonationLock(impersonationLockEnabled);
      sessionStorage.setItem(
        'impersonationLock',
        impersonationLockEnabled.toString()
      );
    },
    []
  );

  const setAccountHandler = useCallback(
    (selectedAccount: string | null) => {
      setAccount(selectedAccount);
      const impersonationLockEnabled =
        selectedAccount !== loggedInAccount && loggedInAccount === 'admin';
      setImpersonationLock(impersonationLockEnabled);
      sessionStorage.setItem(
        'impersonationLock',
        impersonationLockEnabled.toString()
      );
      if (selectedAccount) {
        sessionStorage.setItem('account', selectedAccount);
      } else {
        sessionStorage.removeItem('account');
      }
    },
    [loggedInAccount]
  );

  const fetchAccountList = useCallback(async () => {
    const res = await fetchAccount();
    setAccountList(res || {});
  }, [fetchAccount]);

  const isImpersonating = useMemo(
    () => account != null && loggedInAccount !== account,
    [loggedInAccount, account]
  );
  const contextValue: AuthContextType = {
    user,
    account: account || '',
    setAccount: setAccountHandler,
    fetchAccountList,
    accountList,
    loggedInAccount,
    isImpersonating,
    impersonationLock: impersonationLock && isImpersonating,
    setImpersonationLock: impersonationLockChangeHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
