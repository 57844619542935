import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import useFeatureFlags from '../../../../hooks/useFeatureFlag';
import { Agreements } from '../../../../types/Agreements.types';
import { Journals } from '../../../../types/Journals.types';
import {
  StyledFormTypography,
  StyledQuestionMark,
  StyledSubmit,
  StyledTextArea,
} from '../../presetStyles/formPresets';
import { useAuthContext } from '../../../../contexts/AuthContext';

export interface AgreementFormFieldsProps {
  isAdding: boolean;
  agreementId: string | undefined;
  agreement: Agreements | undefined;
  onPreviewJournals: (journals: Journals[]) => void;
  loading?: boolean;
  onSubmit: (formData: FormDataType) => void;
  onChange?: (formData: FormDataType) => void;
  publishers: string[];
  paymentOptions: string[];
  selectableLicenses: string[];
  paymentIcons: any;
}

export interface FormDataType {
  name: string;
  publisher: string;
  payment: string;
  start_date: string;
  end_date: string;
  payment_url: string;
  file: string;
  description: string;
  notes: string;
  extra_info: string;
  licenses: string[];
}

const initialFormData: FormDataType = {
  name: '',
  publisher: '',
  payment: '',
  start_date: '',
  end_date: '',
  payment_url: '',
  file: '',
  description: '',
  notes: '',
  extra_info: '',
  licenses: [],
};

const StyledInputLabel = styled(InputLabel)`
  color: #575959;
`;

const AgreementFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 18px;
`;

const Column = styled.div`
  flex: 1;
`;

const InternalInfo = styled.div`
  padding: 12px;
  border: 1px solid #e2e8f0;
  background-color: rgba(11, 49, 63, 0.1);
  border-radius: 4px;
`;

export const AgreementFormFields: React.FC<AgreementFormFieldsProps> = ({
  isAdding,
  agreementId,
  agreement,
  onPreviewJournals,
  loading,
  onSubmit,
  onChange,
  paymentIcons,
  paymentOptions,
  publishers,
  selectableLicenses,
}) => {
  const { t } = useTranslation();
  const { impersonationLock } = useAuthContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataType>(initialFormData);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const startDate = useMemo(
    () => dayjs(formData.start_date, 'YYYY-MM-DD'),
    [formData.start_date]
  );
  const endDate = useMemo(
    () => dayjs(formData.end_date, 'YYYY-MM-DD'),
    [formData.end_date]
  );
  const requiredFieldsDone = useMemo(() => {
    return (
      formData.name &&
      formData.publisher &&
      formData.payment &&
      formData.payment_url &&
      formData.start_date &&
      formData.end_date &&
      formData.file &&
      endDate.isAfter(startDate)
    );
  }, [
    endDate,
    formData.end_date,
    formData.file,
    formData.name,
    formData.payment,
    formData.payment_url,
    formData.publisher,
    formData.start_date,
    startDate,
  ]);

  const { previewAgreement } = useAgreements();
  /** USED TO ENABLE FILE PREVIEW ONLY IN STAGING */
  const { enableFilePreviews } = useFeatureFlags();

  const SubmitButton = useMemo(() => {
    if (isAdding) {
      return (
        <div style={{ paddingTop: 14 }}>
          <Box>
            <StyledSubmit
              disabled={!requiredFieldsDone || impersonationLock}
              style={
                !requiredFieldsDone
                  ? { backgroundColor: 'lightGrey', cursor: 'default' }
                  : {}
              }
              type="submit"
              value="Create"
            />
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  marginTop: '6px',
                  marginLeft: '-78px',
                }}
              />
            )}
          </Box>
          <StyledFormTypography variant="body1" sx={{ color: 'grey' }}>
            {t('agreements.asteriskRequiredContinue')}
          </StyledFormTypography>
        </div>
      );
    }
    return (
      <div
        style={{
          paddingTop: 14,
          display: 'flex',
          gap: 14,
          alignItems: 'center',
        }}
      >
        <Button
          disabled={!changesMade || impersonationLock}
          style={
            !changesMade
              ? { backgroundColor: 'lightGrey', cursor: 'default' }
              : {}
          }
          type="submit"
          variant="contained"
        >
          Save changes
        </Button>
        {agreement?.updated_at && agreement?.updated_by && (
          <StyledFormTypography variant="body1" sx={{ color: 'grey' }}>
            {t('agreements.lastUpdatedByOn', {
              email: agreement.updated_by,
              date: dayjs(agreement.updated_at).format('DD-MM-YYYY HH:mm'),
            })}
          </StyledFormTypography>
        )}
      </div>
    );
  }, [
    isAdding,
    changesMade,
    impersonationLock,
    agreement,
    t,
    requiredFieldsDone,
    loading,
  ]);

  useEffect(() => {
    if (!agreementId || !agreement || isLoaded) return;
    setFormData({
      ...formData,
      name: agreement.name ?? '',
      publisher: agreement.publisher ?? '',
      payment: agreement.payment ?? '',
      payment_url: agreement.payment_url ?? 'Could not fetch URL...',
      description: agreement.description ?? '',
      notes: agreement.notes ?? '',
      licenses: agreement.licenses ?? [],
      start_date: agreement.start_date ?? '',
      end_date: agreement.end_date ?? '',
      extra_info: agreement.extra_info ?? '',
    });
    setIsLoaded(true);
  }, [agreementId, agreement, publishers, formData, isLoaded]);

  const handleFileUpload = useCallback(
    async (event: any) => {
      const file = event.target.files?.[0];
      const name = 'file';
      if (file) {
        setFormData({ ...formData, [name]: file });
        onChange?.(formData);
      }
      if (!changesMade && !isAdding) {
        setChangesMade(true);
      }
      if (isAdding && enableFilePreviews) {
        const requestBody: FormData = new FormData();
        requestBody.append('name', 'Preview');
        requestBody.append('publisher', 'Aalborg University');
        requestBody.append('start_date', '2023-01-01 00:00:00 UTC');
        requestBody.append('end_date', '2030-01-01 00:00:00 UTC');
        requestBody.append('payment_url', 'https://www.wikipedia.org');
        requestBody.append('file', file);
        requestBody.append('payment', 'Discounted');
        requestBody.append('description', 'Preview');
        requestBody.append('notes', 'Preview');
        requestBody.append('extra_info', 'Preview');
        requestBody.append('licenses[]', 'CC BY-NC');
        const preview: Agreements = await previewAgreement(requestBody);
        onPreviewJournals(preview.journals ?? []);
      }
    },
    [
      changesMade,
      isAdding,
      enableFilePreviews,
      formData,
      onChange,
      previewAgreement,
      onPreviewJournals,
    ]
  );
  const getEmailString = useCallback((email: string) => {
    if (email.startsWith('mailto:')) {
      return email;
    } else if (email.includes('@')) {
      return `mailto:${email}`;
    } else {
      return email;
    }
  }, []);

  const sanitizePaymentUrl = useCallback((url: string) => {
    return url.replace('mailto:', '');
  }, []);
  const inputChangeHandler = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
      onChange?.(formData);

      if (!changesMade && !isAdding) {
        setChangesMade(true);
      }
    },
    [formData, onChange, changesMade, isAdding]
  );

  const inputDateHandler = useCallback(
    (name: string, date: Dayjs | null) => {
      const dateString = dayjs(date).format('YYYY-MM-DD HH:mm:ss UTC');
      setFormData({ ...formData, [name]: dateString });
      onChange?.(formData);

      if (!changesMade && !isAdding) {
        setChangesMade(true);
      }
    },
    [formData, onChange, changesMade, isAdding]
  );

  const inputSelectChangeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const { name, value } = event.target;

      setFormData({
        ...formData,
        [name]: value,
      });
      onChange?.(formData);

      if (!changesMade && !isAdding) {
        setChangesMade(true);
      }
    },
    [formData, onChange, changesMade, isAdding]
  );

  const inputSyntheticEventHandler = useCallback(
    (event: SyntheticEvent, value: string | null) => {
      if (value) {
        setFormData({ ...formData, publisher: value });
        onChange?.(formData);

        if (!changesMade && !isAdding) {
          setChangesMade(true);
        }
      }
    },
    [formData, onChange, changesMade, isAdding]
  );

  const inputMultipleHandler = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const choices = event.target.value as string[];
      setFormData({ ...formData, [event.target.name]: choices });
      onChange?.(formData);

      if (!changesMade && !isAdding) {
        setChangesMade(true);
      }
    },
    [formData, onChange, changesMade, isAdding]
  );

  const paymentIcon = useMemo(() => {
    if (!paymentIcons) {
      return '';
    }
    const key: string | undefined = Object.keys(paymentIcons).find(
      (key) => key === formData.payment
    );
    if (!key) {
      return '';
    }
    const path: any = paymentIcons[key];
    if (!path.icon) {
      return '';
    }
    return path.icon;
  }, [formData.payment, paymentIcons]);

  const fileIcon = useMemo(() => {
    if (formData.file) {
      return <Check sx={{ marginLeft: '14px' }} />;
    } else {
      return <UploadIcon sx={{ marginleft: '14px' }} />;
    }
  }, [formData.file]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const paymentUrl =
          formData.payment === 'Contact Library'
            ? getEmailString(formData.payment_url)
            : formData.payment_url;
        onSubmit({ ...formData, payment_url: paymentUrl });
        setChangesMade(false);
      }}
    >
      <AgreementFormContainer>
        <Column>
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <StyledInputLabel>
              Publisher *
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title={t('agreements.tooltips.publisher')}
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              id="publisher"
              options={publishers.sort()}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                width: '100%',
                backgroundColor: !formData.publisher ? '#fff4e5' : 'white',
              }}
              value={formData.publisher}
              onChange={(e, v) => inputSyntheticEventHandler(e, v)}
              disabled={impersonationLock}
            />
          </FormControl>
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <StyledInputLabel>
              Agreement name *
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title={t('agreements.tooltips.name')}
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <TextField
            id="name"
            name="name"
            placeholder="Agreement name"
            type="text"
            fullWidth
            sx={{
              backgroundColor: !formData.name ? '#fff4e5' : 'white',
              width: '100%',
            }}
            onChange={inputChangeHandler}
            value={formData.name}
            inputProps={{ maxLength: 150 }}
            disabled={impersonationLock}
          />
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <StyledInputLabel>
              Payment option *
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title={t('agreements.tooltips.payment')}
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <Grid container>
            <Grid item xs={paymentIcon ? 10 : 12}>
              <FormControl sx={{ width: '100%' }}>
                <Select
                  sx={{
                    width: '100%',
                    backgroundColor: !formData.payment ? '#fff4e5' : 'white',
                  }}
                  name="payment"
                  id="payment"
                  value={formData.payment}
                  onChange={inputSelectChangeHandler}
                  disabled={impersonationLock}
                >
                  {paymentOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {paymentIcon && (
              <Grid item xs={1} sx={{ my: 'auto', mx: 'auto' }}>
                <img
                  src={paymentIcon}
                  alt="Custom icon for payment option"
                  width="48"
                />
              </Grid>
            )}
          </Grid>
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <StyledInputLabel>
              {formData.payment === 'Contact Library'
                ? 'Email / URL (must begin with https://) *'
                : 'Agreement URL *'}
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title={t('agreements.tooltips.url')}
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <TextField
            id="payment_url"
            name="payment_url"
            sx={{
              backgroundColor: !formData.payment_url ? '#fff4e5' : 'white',
              width: '100%',
            }}
            placeholder={
              formData.payment === 'Contact Library'
                ? 'Must be a valid email / URL'
                : 'Must begin with https://...'
            }
            type="text"
            fullWidth
            onChange={inputChangeHandler}
            value={sanitizePaymentUrl(formData.payment_url)}
            disabled={impersonationLock}
          />
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <StyledInputLabel>
              Extra information on journal row
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title="Extra information to be shown in each journal of this agreement"
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <StyledTextArea
            id="extra_info"
            name="extra_info"
            placeholder="Extra information"
            onChange={inputChangeHandler}
            value={formData.extra_info}
            style={{ maxWidth: '100%' }}
            disabled={impersonationLock}
            maxLength={400}
          />
          <Box sx={{ display: 'flex', my: 14 }}>
            <div style={{ marginRight: '20px' }}>
              <label
                htmlFor="start_date"
                style={{ color: '#575959', fontSize: '12px' }}
              >
                Start date *
              </label>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={impersonationLock}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    slots={{
                      nextIconButton: (props) => (
                        <ArrowRightIcon
                          {...props}
                          sx={{
                            fontSize: '24px',
                            color: 'gray',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              borderRadius: '50%',
                            },
                          }}
                        />
                      ),

                      previousIconButton: (props) => (
                        <ArrowLeftIcon
                          {...props}
                          sx={{
                            fontSize: '24px',
                            color: 'gray',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              borderRadius: '50%',
                            },
                          }}
                        />
                      ),
                    }}
                    value={startDate}
                    format="YYYY-MM-DD"
                    maxDate={endDate}
                    onChange={(date) => inputDateHandler('start_date', date)}
                    sx={{
                      backgroundColor:
                        !startDate && !formData.start_date
                          ? '#fff4e5'
                          : 'white',
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <label
                htmlFor="end_date"
                style={{ color: '#575959', fontSize: '12px' }}
              >
                End date *
              </label>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={impersonationLock}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    slots={{
                      nextIconButton: (props) => (
                        <ArrowRightIcon
                          {...props}
                          sx={{
                            fontSize: '24px',
                            color: 'gray',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              borderRadius: '50%',
                            },
                          }}
                        />
                      ),

                      previousIconButton: (props) => (
                        <ArrowLeftIcon
                          {...props}
                          sx={{
                            fontSize: '24px',
                            color: 'gray',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              borderRadius: '50%',
                            },
                          }}
                        />
                      ),
                    }}
                    value={endDate}
                    format="YYYY-MM-DD"
                    minDate={dayjs(
                      formData.start_date ? formData.start_date : startDate
                    )}
                    onChange={(date: any) => inputDateHandler('end_date', date)}
                    sx={{
                      backgroundColor:
                        !endDate && !formData.end_date ? '#fff4e5' : 'white',
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Box>
          <div>
            <StyledInputLabel>
              Licenses
              <Tooltip
                sx={{ marginLeft: '6px' }}
                title="Licenses (you are able to select more than one option)"
              >
                <StyledQuestionMark />
              </Tooltip>
            </StyledInputLabel>
          </div>
          <FormControl sx={{ width: '100%' }}>
            <Select
              disabled={impersonationLock}
              sx={{ width: '100%' }}
              name="licenses"
              id="licenses"
              value={formData.licenses}
              renderValue={(selected) => selected.join(', ')}
              multiple
              onChange={(e: SelectChangeEvent<string[]>) => {
                inputMultipleHandler(e);
              }}
            >
              {selectableLicenses.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={formData.licenses.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: 12 }}>
            <label
              htmlFor="file"
              style={{ color: '#575959', fontSize: '12px' }}
            >
              Journals (.xlsx) *
            </label>
          </div>
          <div style={{ marginBottom: 12 }}>
            <Button
              color="primary"
              variant="contained"
              component="label"
              sx={{ width: '100%' }}
              disabled={impersonationLock}
            >
              <input
                type="file"
                name="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                disabled={impersonationLock}
                hidden
              />
              {formData.file
                ? enableFilePreviews
                  ? t('agreements.fileUploadedPreview')
                  : t('agreements.fileUploaded')
                : t('agreements.button')}
              {fileIcon}
            </Button>
          </div>
        </Column>
        <Column>
          <div style={{ marginTop: '12px', marginBottom: '6px' }}>
            <Typography>Internal information</Typography>
          </div>
          <InternalInfo>
            <div style={{ marginTop: '6px', marginBottom: '6px' }}>
              <StyledInputLabel>
                Description
                <Tooltip
                  sx={{ marginLeft: '6px' }}
                  title="A short internal description for this agreement"
                >
                  <StyledQuestionMark />
                </Tooltip>
              </StyledInputLabel>
            </div>
            <StyledTextArea
              id="description"
              name="description"
              placeholder="Description"
              onChange={inputChangeHandler}
              value={formData.description}
              style={{ maxWidth: '100%' }}
              disabled={impersonationLock}
            />
            <div style={{ marginTop: '12px', marginBottom: '6px' }}>
              <StyledInputLabel>
                Notes
                <Tooltip
                  sx={{ marginLeft: '6px' }}
                  title="Additional internal notes"
                >
                  <StyledQuestionMark />
                </Tooltip>
              </StyledInputLabel>
            </div>
            <StyledTextArea
              id="notes"
              name="notes"
              placeholder="Notes"
              onChange={inputChangeHandler}
              value={formData.notes}
              style={{ maxWidth: '100%' }}
              disabled={impersonationLock}
            />
          </InternalInfo>
        </Column>
      </AgreementFormContainer>
      {SubmitButton}
    </form>
  );
};
