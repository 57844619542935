import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Grid,
  AppBar as MuiAppBar,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Toolbar,
} from '@mui/material';

import { HelpOutline, Menu as MenuIcon } from '@mui/icons-material';

import { useAuth0 } from '@auth0/auth0-react';
import { spacing } from '@mui/system';
import { useAuthContext } from '../../contexts/AuthContext';
import AddAccountDialog from './AddAccountDialog';
import { DeleteAccountDialog } from './DeleteAccountDialog';
import NavbarAccountSelector from './NavbarAccountSelector';
import { NavbarImpersonationLock } from './NavbarImpersonationLock';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Button = styled(MuiButton)(spacing);

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

const StyledLogoutButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { loggedInAccount, isImpersonating } = useAuthContext();
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          paddingRight: {
            xs: 2,
            md: 12,
          },
          paddingLeft: {
            xs: 2,
            md: 12,
          },
        }}
      >
        <Toolbar>
          <Grid
            container
            alignItems="center"
            columnGap={{
              xs: 2,
              md: 12,
            }}
          >
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {loggedInAccount === 'admin' && (
              <Grid item xs={3} md={3} xl={4} sx={{ display: 'flex', gap: 6 }}>
                <NavbarAccountSelector />
                {isImpersonating && <NavbarImpersonationLock />}
              </Grid>
            )}
            <Grid item xs />
            {loggedInAccount === 'admin' && (
              <Grid item>
                <AddAccountDialog text="Create" />
              </Grid>
            )}
            {loggedInAccount === 'admin' && (
              <Grid item>
                <DeleteAccountDialog text="Delete" />
              </Grid>
            )}
            <Grid
              item
              sx={{
                display: 'flex',
                gap: {
                  xs: 2,
                  md: 12,
                },
              }}
            >
              <a
                href="https://help.jst.scifree.se/hc/en-gb"
                rel="noreferrer"
                target="_blank"
              >
                <StyledButton variant="text" color="secondary">
                  <HelpOutline sx={{ fontSize: 16, marginRight: 4 }} />
                  {t('general.helpCenter')}
                </StyledButton>
              </a>
              <a
                href="https://help.jst.scifree.se/hc/en-gb/community/topics"
                rel="noreferrer"
                target="_blank"
              >
                <StyledButton variant="text" color="secondary">
                  {t('general.community')}
                </StyledButton>
              </a>
              <StyledLogoutButton
                variant="contained"
                color="primary"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
              >
                {t('general.signOut')}
              </StyledLogoutButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
